import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import { LogoClient } from "@components/UI"
import QuoteLeft from "@svg/ui/quote-left.svg"
import React from "react"
import clientQuoteData from "@json/client-quote.json"

export const Quote = ({ client, single = false }) => {
  const data = useStaticQuery(query)
  const { name, title, quote, background, icon } = clientQuoteData[client]

  return (
    <div className="grid aspect-w-326 aspect-h-742 md:aspect-w-667 md:aspect-h-804 carousel-lg:aspect-w-1436 carousel-lg:aspect-h-920">
      <div className="grid grid-cols-12">
        <div
          className={`relative h-full flex items-center justify-center ${
            single ? "col-span-12" : "col-span-11 carousel-lg:col-span-12 carousel-lg:mr-20"
          } ${background}`}
        >
          <div className="absolute inset-0">
            <div className="hidden w-full h-full carousel-lg:block">
              <GatsbyImage
                className="w-full h-full"
                image={getImage(data[`${client}BackgroundDesktop`])}
                alt={`${name} - Background`}
              />
            </div>
            <div className="hidden w-full h-full md:block carousel-lg:hidden">
              <GatsbyImage
                className="w-full h-full"
                image={getImage(data[`${client}BackgroundTablet`])}
                alt={`${name} - Background`}
              />
            </div>
            <div className="w-full h-full md:hidden">
              <GatsbyImage
                className="w-full h-full"
                image={getImage(data[`${client}BackgroundMobile`])}
                alt={`${name} - Background`}
              />
            </div>
          </div>
          <div className="relative flex justify-center">
            <div className="w-10/12 text-white carousel-lg:w-4/5">
              <div>
                <div className="flex w-5 text-white fill-current md:w-8 carousel-lg:w-10">
                  <QuoteLeft />
                </div>
                <p
                  className="mt-10 text-clamp-20-35"
                  dangerouslySetInnerHTML={{
                    __html: quote,
                  }}
                />
              </div>
              <div className="flex flex-col mt-10 carousel-lg:flex-row carousel-lg:justify-between carousel-lg:items-center md:mt-12">
                <div className="flex items-center">
                  {getImage(data[`${client}Profile`]) && (
                    <div className="w-20 h-20 overflow-hidden rounded-full carousel-lg:h-30 carousel-lg:w-30 z-1">
                      <GatsbyImage className="relative z-0" image={getImage(data[`${client}Profile`])} alt={name} />
                    </div>
                  )}
                  <div
                    className={`${
                      getImage(data[`${client}Profile`]) ? `ml-4 md:ml-6 carousel-lg:ml-10` : ``
                    } flex flex-1 flex-col`}
                  >
                    <span className="font-bold text-clamp-18-26">{name}</span>
                    <span
                      className="text-14 md:text-22"
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />
                  </div>
                </div>
                <div className="flex mt-32 text-white fill-current md:mt-20 xl:ml-20 carousel-lg:mt-0">
                  <div style={{ height: `${icon.height}px`, width: `${icon.width}px` }}>
                    <LogoClient slug={client} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const query = graphql`
  query {
    adProfile: file(relativePath: { eq: "client-quotes/ad/profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adBackgroundDesktop: file(relativePath: { eq: "client-quotes/ad/background-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adBackgroundTablet: file(relativePath: { eq: "client-quotes/ad/background-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adBackgroundMobile: file(relativePath: { eq: "client-quotes/ad/background-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adpProfile: file(relativePath: { eq: "client-quotes/adp/profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adpBackgroundDesktop: file(relativePath: { eq: "client-quotes/adp/background-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adpBackgroundTablet: file(relativePath: { eq: "client-quotes/adp/background-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adpBackgroundMobile: file(relativePath: { eq: "client-quotes/adp/background-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ansProfile: file(relativePath: { eq: "client-quotes/ans/profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ansBackgroundDesktop: file(relativePath: { eq: "client-quotes/ans/background-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ansBackgroundTablet: file(relativePath: { eq: "client-quotes/ans/background-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ansBackgroundMobile: file(relativePath: { eq: "client-quotes/ans/background-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    childrenProfile: file(relativePath: { eq: "client-quotes/childrens/profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    childrenBackgroundDesktop: file(relativePath: { eq: "client-quotes/childrens/background-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    childrenBackgroundTablet: file(relativePath: { eq: "client-quotes/childrens/background-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    childrenBackgroundMobile: file(relativePath: { eq: "client-quotes/childrens/background-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosProfile: file(relativePath: { eq: "client-quotes/marcos/profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosBackgroundDesktop: file(relativePath: { eq: "client-quotes/marcos/background-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosBackgroundTablet: file(relativePath: { eq: "client-quotes/marcos/background-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosBackgroundMobile: file(relativePath: { eq: "client-quotes/marcos/background-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    pricelineBackgroundDesktop: file(relativePath: { eq: "client-quotes/priceline/background-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    pricelineBackgroundTablet: file(relativePath: { eq: "client-quotes/priceline/background-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    pricelineBackgroundMobile: file(relativePath: { eq: "client-quotes/priceline/background-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    pricelineProfile: file(relativePath: { eq: "client-quotes/priceline/profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohProfile: file(relativePath: { eq: "client-quotes/ricoh/profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohBackgroundDesktop: file(relativePath: { eq: "client-quotes/ricoh/background-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohBackgroundTablet: file(relativePath: { eq: "client-quotes/ricoh/background-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohBackgroundMobile: file(relativePath: { eq: "client-quotes/ricoh/background-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    spectrumBackgroundDesktop: file(relativePath: { eq: "client-quotes/spectrum/background-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    spectrumBackgroundTablet: file(relativePath: { eq: "client-quotes/spectrum/background-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    spectrumBackgroundMobile: file(relativePath: { eq: "client-quotes/spectrum/background-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
